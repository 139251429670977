export const RADIAL_BAR_OPTIONS = {
    chart: {
        type: "radialBar"
    },
    colors:["#E1248E"],
    plotOptions: {
        radialBar: {
            dataLabels: {
                show: true,
                name: {
                    offsetY: 10,
                    fontWeight: 900,
                    fontSize: "30px",
                },
                value: {
                    show: false,
                }
            },
        }
    },
    labels: ['0']
};

export const STRAIGHT_LINE_OPTIONS = {
    chart: {
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false,
        },
        animations: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight'
    },
    xaxis: {
        type: 'datetime',
        tickPlacement: 'on',
        categories: [],
        labels: {
            datetimeUTC: false,
            format: 'HH:mm',
        },
    },
    yaxis: {
        min: 0,
        max: 5,
        labels: {
            formatter: function (value) { return parseFloat(value).toFixed(1); },
        },
    },
    tooltip: {
        x: {
            format: 'h:mm TT'
        }
    },
};