<template>
  <div>
    <v-skeleton-loader v-if="latestTargetLoading"
        v-bind="{class: 'mb-3'}"
        height="100%"
        type="card-heading, image"
    ></v-skeleton-loader>
    <v-card tile class="mb-3" elevation="5" v-if="!latestTargetLoading">
        <div class="text-subtitle-1 white--text primary font-weight-bold pa-1 pl-2 pr-2 text-capitalize" v-if="currentTarget">
            <span v-if="currentTarget && currentTarget.type">{{(targetTypeList.find(item => item.value === currentTarget.type)).text}}</span>
            <span v-if="currentTarget && currentTarget.title">{{ '  -  '+ currentTarget.title }}</span>
        </div>
        <v-card-subtitle class="text-h5 primary--text font-weight-bold text-center" >
            <v-row class="ma-0 pa-0" align="center" justify="center">
                <v-col cols="6" class="text-break">
                    <div class="target-title" v-if="currentTarget && currentTarget.fullTitle" v-html="currentTarget.fullTitle"></div> 
                </v-col>
                <v-col cols="6" class="d-flex justify-start align-center">
                    <apexchart ref="remainingChart"
                        width="100%"
                        height="180"
                        type="radialBar"
                        :options="chartOptions"
                        :series="chartSeries"
                    />
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" justify="center" align="center">
                <v-col cols="12" class="pa-0 text-break secondary--text">
                        <span v-if="currentTarget && currentTarget.deleted_at">Expired at </span>
                        <span v-if="!currentTarget">Target Not Created</span>
                        <span v-if="!latestTargetLoading && currentTarget && !currentTarget.deleted_at">{{ targetTime | secondsInMinutes }}</span>
                        <v-skeleton-loader v-if="latestTargetLoading"
                            v-bind="{class: 'skeleteon-loader'}"
                            type="list-item"
                        ></v-skeleton-loader>
                        <span v-if="currentTarget && currentTarget.deleted_at">{{ currentTarget.deleted_at }}</span>
                </v-col>
                <v-col cols="12" v-if="targetEditUser" class="pa-0 mt-1 text-subtitle-1 text-break font-weight-medium primary--text">
                    Current Target Editor: {{ targetEditUser.text }}
                </v-col>
            </v-row>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
            <v-row class="ma-0" align="center" justify="space-around">
                <v-btn text class="text-capitalize text-subtitle-2 target-button" color="info" :disabled="((currentTarget && currentTarget.id && !currentTarget.deleted_at) ||
                    ($helpers.getCurData('curUserId') && targetEditUser && $helpers.getCurData('curUserId') !== targetEditUser.value))? true : false"
                    @click="targetPage = 'create' ; openTargetModal = true;">
                    <v-icon dark class="mb-1" large color="info">mdi-plus</v-icon>
                    New
                </v-btn>
                <v-btn :disabled="!currentTarget || ($helpers.getCurData('curUserId') && targetEditUser && $helpers.getCurData('curUserId') !== targetEditUser.value)" text class="text-capitalize text-subtitle-2 target-button" color="info"
                    @click="targetPage = currentTarget.deleted_at ? 'extend' : 'edit';openTargetModal = true;">
                    <v-icon dark class="mb-1" large color="info">mdi-pencil</v-icon>
                    {{ currentTarget && currentTarget.deleted_at ? 'Extend' : 'Edit' }}
                </v-btn>
                <v-btn :disabled="!currentTarget || !currentTarget.id" text class="text-capitalize text-subtitle-2 target-button" color="info"
                    @click="findModal =true;">
                    <v-icon dark class="mb-1" large color="info">mdi-currency-usd</v-icon>
                    Find
                </v-btn>
                <v-btn :disabled="!currentTarget || !currentTarget.id || (currentTarget && currentTarget.deleted_at) || ($helpers.getCurData('curUserId') && targetEditUser && $helpers.getCurData('curUserId') !== targetEditUser.value) ? true : false"
                    text class="text-capitalize text-subtitle-2 target-button" color="info" @click="stopTarget">
                    <v-icon dark class="mb-1" large color="info">mdi-stop</v-icon>
                    end
                </v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
    <TargetChartGraph />
    
    <TargetModal v-if="openTargetModal" :page="targetPage" :modalTarget="openTargetModal" @closeModal="openTargetModal = false;" />
    <FindTargetModal v-if="findModal" :findModal="findModal" @closeModal="findModal=false"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import globalHelper from '@/helpers/global.helper';
import { RADIAL_BAR_OPTIONS } from '@/constants/chart-options';

export default {
    props: ['targetEditor'],
    components: {
        TargetChartGraph: () => import("@/pages/active-campaign/producer/components/target-component/TargetGraph"),
        TargetModal: () => import("@/pages/active-campaign/producer/components/target-component/TargetModal"),
        FindTargetModal: () => import("@/pages/active-campaign/producer/components/target-component/FindTargetModal"),
    },
    data: (vm) => ({
        targetPage: 'create',
        openTargetModal: false,
        findModal: false,
        chartOptions: RADIAL_BAR_OPTIONS,
        chartSeries:  [0],
        currentTarget: {},
        targetTime: 0,
        targetTimer: null,
        targetEditUser: null
    }),
    computed: {
        ...mapGetters(["currentTargetModel", "targetTypeList", "latestTargetLoading", "targetTypeList"]),

        currTargetName() {
            if (this.currentTarget && this.currentTarget.type) {
                return (this.targetTypeList.find(item => item.value === this.currentTarget.type)).text;
            } else {
                return '';
            }
        }
    },
    watch: {
        'currentTargetModel': function (newValue) {
            this.currentTarget = newValue;
            this.elapsedTargetUpdated();
            setTimeout(() => {
                this.chartUpdate();
            }, 400);
        },
        targetEditor(newValue) {
            this.targetEditUser = newValue;
        }
    },
    filters: {
        amPmTime: function(date) {
            return globalHelper.getMomentDatas('hh:mm A', date);
        },
        secondsInMinutes: function(seconds) {
            return globalHelper.givenSecToTime(seconds, true);
        }
    },
    created() {
        this.getLatestTarget();
        this.currentTarget = this.currentTargetModel;
        this.elapsedTargetUpdated();
        this.targetEditUser = this.targetEditor ;
    },
    mounted() {
        setTimeout(() => {
            this.chartUpdate();
        }, 400);
    },
    methods: {
        ...mapActions(["updateTarget", "deleteTarget", "getLatestTarget"]),

        resetTarget() {
            this.targetTime = 0;
            clearInterval(this.targetTimer);
        },
        startTarget() {
            this.targetTimer = setInterval(() => {
                if (this.targetTime === 0) this.resetTarget();
                else this.targetTime--;
            }, 1000);
        },
        togglePlayTarget() {
            if (this.currentTarget) {
                this.currentTarget['status'] = !this.currentTarget.status || this.currentTarget.status === 0 ? 1 : 0;
                this.updateTarget(this.currentTarget);
            }
        },
        stopTarget() {
            this.deleteTarget(this.currentTarget.id);
        },
        chartUpdate() {
            const self = this;
            if (this.$refs.remainingChart && self.currentTarget) {
                this.$refs.remainingChart.updateSeries([self.currentTarget.progress || 0]);
                let label = self.currentTarget.remaining || 0;
                this.$refs.remainingChart.updateOptions({ labels: [ label ]});
            }
        },
        elapsedTargetUpdated() {
            this.targetState = 0;
            if (this.currentTarget && !this.currentTarget.deleted_at) {
                const currentExist =  this.$helpers.getMomentDiff(this.currentTarget.end_time, 'seconds'),
                    durationTime = this.$helpers.getMomentDiff(this.currentTarget.start_time, 'seconds', this.currentTarget.end_time),
                    elapsedSec = this.$helpers.getMomentDiff(this.currentTarget.start_time, 'seconds');
                this.targetTime = currentExist < 0 ? durationTime - elapsedSec : 0;
                if (this.targetTime !== 0) {
                    clearInterval(this.targetTimer);
                    this.startTarget();
                }
            }
        },
    },
    beforeDestroy() {
        if (this.targetTimer) {
            clearInterval(this.targetTimer);
            this.targetTimer = null;
        }
    }
}
</script>